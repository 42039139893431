<template>
<div id="InteractiveMenu">

  <div id="menu-container">
    <div id="top_description">
      Click me...
    </div>

    <div id="menu" class="closed">
      <div class="messages button"></div>
      <div class="music button"></div>
      <div class="home button"></div>
      <div class="places button"></div>
      <div class="bookmark button"></div>
      <div class="main button">Menu</div>
    </div>
    <div id="bottom_description">
      Click & hold anywhere <br> inside this blue box.
      <div>And then select an option :)</div>
    </div>
  </div>

  <div id="wheel-container">
    <div id="wheel">
      <div class="arc">
        <div class="inner-icon"></div>
      </div>
      <div class="arc">
        <div class="inner-icon"></div>
      </div>
      <div class="arc">
        <div class="inner-icon"></div>
      </div>
      <div class="arc">
        <div class="inner-icon"></div>
      </div>
      <div class="arc">
        <div class="inner-icon"></div>
      </div>
      <div class="arc">
        <div class="inner-icon"></div>
      </div>
      <div class="arc">
        <div class="inner-icon"></div>
      </div>
      <div class="arc">
        <div class="inner-icon"></div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'InteractiveMenu',
  setup () {

    onMounted(() => {

      const menu = document.getElementById('menu');
      menu.onpointerdown = () => {
        menu.classList.toggle('closed');
      };


      const mainContainer = document.getElementById('InteractiveMenu');
      const container = document.getElementById('wheel-container');
      const wheel = document.querySelector('#wheel');
      let showing, anchorX, anchorY;

      container.oncontextmenu = event => event.preventDefault() && event.stopPropagation();
      container.onpointerdown = onPointerDown;
      mainContainer.onpointerup = onPointerUp;
      container.onpointermove = onPointerMove;


      function onPointerDown ({ clientX: x, clientY: y }) {
        let _container = container.getBoundingClientRect();
        showing = true;
        anchorX = x - _container.left;
        anchorY = y - _container.top;
        wheel.style.left = anchorX + 'px';
        wheel.style.top = anchorY + 'px';
        wheel.classList.add('on');
      }


      function onPointerUp () {
        showing = false;
        wheel.setAttribute('data-chosen', 0 + '');
        wheel.classList.remove('on');
      }


      function onPointerMove (event) {
        let _container = container.getBoundingClientRect();
        if (!showing) return;
        let x = event.x - _container.left;
        let y = event.y - _container.top;
        let dx = x - anchorX;
        let dy = y - anchorY;
        let distanceToCenter = Math.sqrt(dx * dx + dy * dy);
        let index = 0;

        if (distanceToCenter > 50) {
          let deg = Math.floor(Math.atan2(dy, dx) * 180 / Math.PI);
          if (deg > 0 && deg < 45) index = 1;
          if (deg > 45 && deg < 90) index = 2;
          if (deg > 90 && deg < 135) index = 3;
          if (deg > 135 && deg < 180) index = 4;
          if (deg < 0 && deg > -45) index = 8;
          if (deg < -45 && deg > -90) index = 7;
          if (deg < -90 && deg > -135) index = 6;
          if (deg < -135 && deg > -180) index = 5;
        }

        wheel.setAttribute('data-chosen', index + '');
      }


    });
  },
};
</script>

<style lang="stylus" scoped>
$saturation = 100%
$lightness = 75%
#InteractiveMenu
  position absolute
  overflow hidden
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  border-radius 20px
  font 200 28px Averta, sans-serif;
  letter-spacing 3px
  line-height 60px
  box-shadow 0 10px 30px 0 rgba(37, 44, 97, 0.15), 0 4px 18px 0 rgba(93, 100, 148, 0.2)
  background-image linear-gradient(#fff, #f5f5fa)
  #menu-container
    position absolute
    top 50px
    right 50%
    bottom 50px
    left 50px
    border-radius 10px
    #top_description
      font 200 20px Averta
      position absolute
      top 0
      left 0
    #bottom_description
      font 200 20px / 32px Averta
      position absolute
      bottom 0
      right 20px
      text-align right
      div
        color black
        font-size 12px
    #menu
      width 80px
      height 80px
      user-select none
      position absolute
      top 60px
      left 10px
      transition transform 1.125s cubic-bezier(0.39, 1.52, 0.46, 0.92)
      transform scale3d(1, 1, 1)
      transform-origin top left
      cursor pointer
      &.closed
        .main
          box-shadow 0 0 0 5px rgba(3, 15, 1, 0)
          background-color #000
          color #f8f7f2
        .home
          transform translate3d(-225%, -10%, 0) rotate(-180deg)
        .music
          transform translate3d(-198.36244%, -89.193835%, 0) rotate(-180deg)
        .messages
          transform translate3d(-153.221215%, -151.008915%, 0) rotate(-180deg)
        .places
          transform translate3d(-91.981535%, -199.78284%, 0) rotate(-180deg)
        .bookmark
          transform translate3d(-9.51056%, -228.09016%, 0) rotate(-180deg)
      .button
        width 80px
        height 80px
        user-select none
        position absolute
        top 0
        left 0
        border-radius 50%
        mix-blend-mode multiply
        background-position center center
        background-repeat no-repeat
        background-size 33%
        transition transform 0.75s cubic-bezier(0.39, 1.52, 0.46, 0.92)
        will-change transform
      .main
        font 18px AvertaStd, sans-serif
        color #030f01
        mix-blend-mode normal
        box-shadow 0 0 0 5px rgba(3, 15, 1, 0.1)
        transition border-color 0.75s, color 0.75s
        will-change color, border-color, background
        display flex
        align-items center
        justify-content center
      .home
        background-color hsl(193, $saturation, $lightness)
        top 0
        left 225%
        background-image url("../../public/icons-slider/Money_Protection.svg")
        transition-duration 0.525s
      .music
        background-color hsl(143, $saturation, $lightness)
        top 86.103675%
        left 207.873%
        background-image url("../../public/icons-slider/Ecology.svg")
        transition-duration 0.675s
      .messages
        background-color hsl(44, $saturation, $lightness)
        top 159.099075%
        left 159.099075%
        background-image url("../../public/icons-slider/Gallery.svg")
        transition-duration 0.825s
      .places
        background-color hsl(21, $saturation, $lightness)
        top 207.873%
        left 86.103675%
        background-image url("../../public/icons-slider/Startup.svg")
        transition-duration 0.975s
      .bookmark
        background-color hsl(0, $saturation, $lightness)
        top 225%
        left 0
        background-image url("../../public/icons-slider/Discount.svg")
        transition-duration 1.125s
  #wheel-container
    position absolute
    top 50px
    right 50px
    bottom 50px
    left 50%
    border-radius 10px
    background rgba(100, 149, 237, 0.15)
    #wheel
      position absolute
      top 0
      left 0
      width 250px
      height 250px
      &.on
        .arc
          transition-timing-function cubic-bezier(0, 0.5, 0.5, 1.5)
          transform scale(1) rotate(var(--rotation)) !important
          opacity 1
      .arc
        position absolute
        bottom 0
        right 0
        width 100%
        height 100%
        background radial-gradient(circle at 0 0, transparent, transparent 20%, var(--color) 20%, var(--color) 40%, transparent 40%, transparent)
        transition-timing-function cubic-bezier(0.4, -0.4, 0.7, -0.3)
        transition-property transform, opacity
        transition-duration 0.3s
        clip-path polygon(0 0, 90% 100%, 7% 100%)
        transform scale(0) rotate(var(--rotation))
        transform-origin 0 0
        opacity 0
        .inner-icon
          position absolute
          top 30%
          left 10%
          width 20%
          height 20%
        &:nth-child(1)
          transition-delay 0.015s
          --color hsl(-45deg, $saturation, $lightness)
          --rotation -45deg
          .inner-icon
            background url("../../public/icons-slider/Compass.svg") 20% center / 25px no-repeat
        &:nth-child(2)
          transition-delay 0s
          --color hsl(0deg, $saturation, $lightness)
          --rotation 0deg
          .inner-icon
            background url("../../public/icons-slider/Discount.svg") 20% center / 25px no-repeat
        &:nth-child(3)
          transition-delay 0.015s
          --color hsl(45deg, $saturation, $lightness)
          --rotation 45deg
          .inner-icon
            background url("../../public/icons-slider/Ecology.svg") 20% center / 25px no-repeat
        &:nth-child(4)
          transition-delay 0s
          --color hsl(90deg, $saturation, $lightness)
          --rotation 90deg
          .inner-icon
            background url("../../public/icons-slider/Gallery.svg") 20% center / 25px no-repeat
        &:nth-child(5)
          transition-delay 0.015s
          --color hsl(135deg, $saturation, $lightness)
          --rotation 135deg
          .inner-icon
            background url("../../public/icons-slider/Money_Protection.svg") 20% center / 25px no-repeat
        &:nth-child(6)
          transition-delay 0s
          --color hsl(180deg, $saturation, $lightness)
          --rotation 180deg
          .inner-icon
            background url("../../public/icons-slider/Settings.svg") 20% center / 25px no-repeat
        &:nth-child(7)
          transition-delay 0.015s
          --color hsl(225deg, $saturation, $lightness)
          --rotation 225deg
          .inner-icon
            background url("../../public/icons-slider/Startup.svg") 20% center / 25px no-repeat
        &:nth-child(8)
          transition-delay 0s
          --color hsl(270deg, $saturation, $lightness)
          --rotation 270deg
          .inner-icon
            background url("../../public/icons-slider/Tags.svg") 20% center / 25px no-repeat
      &[data-chosen="1"]
        .arc:nth-child(1)
          transform scale(1.15) rotate(var(--rotation)) !important
          filter brightness(140%)
          opacity 1
      &[data-chosen="2"]
        .arc:nth-child(2)
          transform scale(1.15) rotate(var(--rotation)) !important
          filter brightness(140%)
          opacity 1
      &[data-chosen="3"]
        .arc:nth-child(3)
          transform scale(1.15) rotate(var(--rotation)) !important
          filter brightness(140%)
          opacity 1
      &[data-chosen="4"]
        .arc:nth-child(4)
          transform scale(1.15) rotate(var(--rotation)) !important
          filter brightness(140%)
          opacity 1
      &[data-chosen="5"]
        .arc:nth-child(5)
          transform scale(1.15) rotate(var(--rotation)) !important
          filter brightness(140%)
          opacity 1
      &[data-chosen="6"]
        .arc:nth-child(6)
          transform scale(1.15) rotate(var(--rotation)) !important
          filter brightness(140%)
          opacity 1
      &[data-chosen="7"]
        .arc:nth-child(7)
          transform scale(1.15) rotate(var(--rotation)) !important
          filter brightness(140%)
          opacity 1
      &[data-chosen="8"]
        .arc:nth-child(8)
          transform scale(1.15) rotate(var(--rotation)) !important
          filter brightness(140%)
          opacity 1
</style>